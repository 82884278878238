<template>
  <div
    class="bulgy-radios d-flex align-center wrap"
    :class="{ vertical: props.vertical === '' }"
    role="radiogroup"
    aria-labelled-by="bulgy-radios-label"
  >
    <label
      v-for="(item, index) in props.items"
      :key="index"
      class="label-container"
    >
      <input
        type="radio"
        name="options"
        :checked="props.modelValue === index"
        @change="select(index)"
      />
      <span class="radio"></span>
      <span class="label">{{ item.charAt(0).toUpperCase() + item.slice(1) }}</span>
    </label>
  </div>
</template>

<script setup>
const props = defineProps({
  vertical: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array,
    required: true,
  },
  modelValue: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const select = (index) => {
  console.log('Selected index:', index);
  emit('update:modelValue', index);
};
</script>

<style scoped lang="less">
.vertical {
  flex-direction: column;
  align-items: flex-start;
}

.bulgy-radios label {
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 1.5rem;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  color: var(--black);
  letter-spacing: 1px;
  padding: 5px 0;
}

.bulgy-radios label:hover input:not(:checked) ~ .radio {
  opacity: 0.8;
}

.bulgy-radios .label {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.bulgy-radios .label span {
  line-height: 1em;
}

.bulgy-radios input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  left: -9999px; /* Hide the radio button */
}

.bulgy-radios input:checked ~ .radio {
  transition: background 0.3s;
}

.bulgy-radios input:checked ~ .radio::after {
  opacity: 1;
}

.radio {
  position: relative;
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
  margin-right: 8px;
}

.radio::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--primary);
  opacity: 0;
}

@media only screen and (max-width: 576px) {
  .bulgy-radios {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
