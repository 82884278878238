import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";

import auth from "./auth";
import user from "./user";
import modals from "./modals";
import billing from "./billing";
import schedule from "./schedule";
import toolbars from "./toolbars";
import calendar from "./calendar";
import attendees from "./attendees";
import notifications from "./notifications";

export default createStore({
    modules: {
        user,
        modals,
        toolbars,
        calendar,
        schedule,
        attendees,
        notifications,
        billing,
        auth,
    },
    plugins: [
        createPersistedState({
            paths: [
                "user",
                "modals",
                "schedule",
                "attendees",
                "toolbars",
                "calendar",
                "notifications",
                "billing",
                "auth",
            ],
        }),
    ],
});