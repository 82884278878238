import { get } from "../assets/functions";
import dayjs, { utc } from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import router from "../router";
import store from "./index";

dayjs.extend(weekOfYear);
dayjs.extend(dayOfYear);
dayjs.extend(isoWeek);

export default {
    namespaced: true,
    state: {
        view: "week",
        week: 0,
        month: 0,
        day: 0,
        timeFrom: 1,
        timeTo: 24,
        events: [],
        upcoming: [],
        date: dayjs(),
        interval: null,
        sideBar: false,
        filterGlobal: false,
        filterLocal: true,
    },
    mutations: {
        changeTimeFrom(state, time) {
            state.timeFrom = time;
        },
        changeTimeTo(state, time) {
            state.timeTo = time;
        },
        calendarView(state, view) {
            state.view = view;
        },
        incrementMonth(state) {
            state.date = dayjs(state.date).add(1, "month");
            state.month++;
        },
        decrementMonth(state) {
            state.date = dayjs(state.date).subtract(1, "month");
            state.month--;
        },
        incrementWeek(state) {
            state.date = dayjs(state.date).add(1, "week");
            state.week++;
        },
        decrementWeek(state) {
            state.date = dayjs(state.date).subtract(1, "week");
            state.week--;
        },
        incrementDay(state) {
            state.date = dayjs(state.date).add(1, "day");
            state.day++;
        },
        decrementDay(state) {
            state.date = dayjs(state.date).subtract(1, "day");
            state.day--;
        },
        setData(state, response) {
            state.events = response;
        },
        today(state) {
            state.day = dayjs().dayOfYear();
            state.week = dayjs()
                .subtract(1, "day")
                .week();
            state.month = dayjs().month();
            state.date = dayjs();
        },
        loadData(state) {
            state.date = dayjs(state.state);
        },
        setDate(state, response) {
            state.date = response;
        },
        sideBar(state, val) {
            state.sideBar = val;
        },
        setUpcoming(state, response) {
            state.upcoming = response;
        },
        interval(state, val) {
            state.interval = val;
        },
        filterGlobal(state, val) {
            state.filterGlobal = val;
        },
        filterLocal(state, val) {
            state.filterLocal = val;
        },
    },
    actions: {
        async getCalendarEvents(context) {
            let view = context.state.view;
            let day = context.state.date;
            if (view === "week") view = "isoWeek";
            if (view === "event") view = "month";
            let start = day.startOf(view).subtract(1, 'day').unix();
            let end = day.endOf(view).add(1, 'day').unix();
            let base = "/api/events/calendar?start=";
            let url = start + "&end=" + end;
            let response = await get(base + url);
            context.commit("setData", response);
            return;
        },
        goToLastCalendarView(context) {
            let memoryDate = context.state.date.format("YYYY-MM-DD");
            let memoryView = context.state.view;
            let url = "/calendar/" + memoryView + "/" + memoryDate;
            if (router.currentRoute.name !== "CalendarDate") router.push(url);
            return url;
        },
        async getUpcomingEvents(context) {
            let response = await get("/api/events/upcoming");
            if (!response) return store.dispatch("user/logout");
            store.commit("notifications/raw", response);
            context.commit("setUpcoming", response);
        },
        async innitGetUpcomingEvents(context) {
            context.dispatch("getUpcomingEvents");
            context.dispatch('clearUpcomingEvents');
            let interval = setInterval(() => {
                context.dispatch("getUpcomingEvents");
            }, 1000 * 60);
            context.commit("interval", interval);

        },
        async clearUpcomingEvents(context) {
            clearInterval(context.state.interval);
        },
    },
};
