<template>
  <div class="bulgy-radios d-flex align-center wrap " role="radiogroup" aria-labelled-by="bulgy-radios-label">
    <hr class="hr w100 ma-0">
    <button v-for="(item, index) in options" :key="index" class="d-flex d-col w100" @click="select(item)">
      <label class="d-flex w100 item transitions">
        <img class="ml-10 flag" :src="'/img/static/' + item + '-flag.svg'" alt="">
        <input type="radio" name="options" :checked="modelValue === options[index]" @click.stop>
        <span class="radio" /> 
        <span class="label uppercase">{{ flagName(item) }}</span>
      </label>
      <hr class="hr w100 ma-0">
    </button>
  </div>
</template>

<script setup>

const props = defineProps(['modelValue', 'options']);
const emit = defineEmits();

const select = (item) => {
  emit('childEvent', item);
};

const flagName = (val) => {
  if (val === "en") return "english";
  if (val === "de") return "Deutsch";
  if (val === "fr") return "Français";
  if (val === "sk") return "Slovenčina";
  if (val === "pl") return "Polski";
  if (val === "el") return "Ελληνικά";
  if (val === "es") return "Español";
  if (val === "nl") return "Nederlands";
  if (val === "hi") return "हिन्दी";
  if (val === "zh-cn") return "中文 (简体)";
  if (val === "zh-tw") return "中文 (繁體)";
  if (val === "pt") return "Português";
  return val;
};
</script>

<style scoped lang='less'>
.vertical {
  flex-direction: column;
  align-items: flex-start;
}

.bulgy-radios label {
  position: relative;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--black);
  letter-spacing: 1px;
  box-sizing: border-box;
}

.bulgy-radios label:hover input:not(:checked)~.radio {
  opacity: 0.8;
}

.bulgy-radios .label {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  height: 40px;
}

.item:hover {
  background-color: var(--shade3);
}

.bulgy-radios{
  padding: 10px;
  border-radius: 10px;
  background-color: var(--shade2);
}

.bulgy-radios .label span {
  line-height: 1em;
}

.bulgy-radios input {
  position: absolute;
  cursor: pointer;
  height: 0;
  width: 0;
  left: -2000px;
  display: none;
}

.bulgy-radios input:checked~.radio {
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.bulgy-radios input:checked~.radio::after {
  opacity: 1;
}

.bulgy-radios input:checked~.label {
  color: var(--black);;
}

.hr {
  box-sizing: border-box;
}

.flag {
  max-width: 24px;
  max-height: 40px;
}

.radio {
  position: absolute;
  right: 10px;
  top: 13px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid white;
}

.radio::after {
  content: "";
  position: absolute;
  opacity: 0;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--primary);
}

@media only screen and (max-width: 576px) {
  .bulgy-radios {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>