const getDefaultState = () => {
    return {
        account: false,
        meeting: false,
        billing: false,
        attendee: false,
        addGroup: false,
        timezone: false,
        repeating: false,
        notifications: false,
        deleteEventConfirmation: false,
        deleteAttendeeConfirmation: false,
        firstTimeWizard: false,
        updateAttendeeConfirmation: false,
        rateExperience: true,
        saveEventConfirmation: false,
        deleteEventSimpleConfirmation: false,
        terms: false,
        appInfo: false,
        auth2fa : false,
        updateProfile: false
    };
};

export default {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        deleteEventSimpleConfirmation(state, value) {
            state.deleteEventSimpleConfirmation = value;
        },
        saveEventConfirmation(state, value) {
            state.saveEventConfirmation = value;
        },
        rateExperience(state, value) {
            state.rateExperience = value;
        },
        updateProfile(state, value) {
            state.updateProfile = value;
        },
        appInfo(state, value) {
            state.appInfo = value;
        },
        updateAttendeeConfirmation(state, value) {
            state.updateAttendeeConfirmation = value;
        },
        deleteEventConfirmationModal(state, value) {
            state.deleteEventConfirmation = value;
        },
        deleteAttendeeConfirmationModal(state, value) {
            state.deleteAttendeeConfirmation = value;
        },
        firstTimeWizardModal(state, value) {
            state.firstTimeWizard = value;
        },
        repeatingModal(state, value) {
            state.repeating = value;
        },
        notificationsModal(state, value) {
            state.notifications = value;
        },
        timezoneModal(state, value) {
            state.timezone = value;
        },
        terms(state, value) {
            state.terms = value;
        },
        attendeeModal(state, value) {
            state.attendee = value;
        },
        billingModal(state, value) {
            state.billing = value;
        },
        accountModal(state, value) {
            state.account = value;
        },
        meetingModal(state, value) {
            state.meeting = value;
        },
        addGroup(state, value) {
            state.addGroup = value;
        },
        auth2fa(state, value) {
            state.auth2fa = value;
        },
    },
};
