<template>
    <FocusLoop>
        <div class="modal_page center">
            <form class="modal_window d-flex d-col space-between" @submit.prevent="post">
                <div class="d-flex space-between align-center">
                    <div class="font-18">
                        <!-- {{ $t("modal.custom.title") }} -->
                        Notifications
                    </div>
                    <button type="button" class="center" @click="closeModal">
                        <s-icon height="16" color="white">
                            close
                        </s-icon>
                    </button>
                </div>
                <div ref="dropDownRef" class="col gap-20">
                    <span class="opacity-75 weight-300">Choose how long you wish to be notified before your meeting</span>
                    <div class="col none">
                        <span>Notification method</span>
                        <s-dropdown v-model="type" :items="itemsType" />
                    </div>
                    <div class="row gap-20">
                        <div class="col w100 gap-5">
                            <span>Time before meeting</span>
                            <s-dropdown v-model="time" block class="grow" :items="options" />
                        </div>
                        <div class="col justify-end">
                            <s-btn :height="isMobile" class="primary" type="button" width="110" @click="add()">
                                {{ $t("schedule.add") }}
                            </s-btn>
                        </div>
                    </div>
                    <div class="item1 mt-10 missingText sm-margin col gap-5">
                        <span class="mb-10">{{ items.length }} notifications will be sent before your meeting.</span>
                        <div v-for="(item, index) in items.slice().reverse()" :key="index" class=" items align-center">
                            {{ index + 1 }}. {{ item.text }}
                            <button type="button" class="center close" @click="del(index)">
                                <s-icon height="16" color="primary">
                                    close
                                </s-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-end mt-40">
                    <s-btn type="button" class="mr-10 black--text" @click="closeModal">
                        {{ $t("modal.cancel") }}
                    </s-btn>
                    <s-btn type="submit" class="primary" @click="closeModal">
                        {{ $t("modal.save") }}
                    </s-btn>
                </div>
            </form>
        </div>
    </FocusLoop>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { gsap } from "gsap";
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useMq } from "vue3-mq";

const store = useStore();
const router = useRouter();
const { t } = useI18n();
const mq = useMq();

const dropDownRef = ref(null);
const isMobile = ref(false);
const isTablet = ref(false);
const expand = ref(true);
const type = ref("");
const time = ref("");
const items = ref([]);

const add = () => {
    let flag = false;
    let data = {
        text: type.value.display + t("schedule.set") + time.value.display,
        type: type.value.value,
        time: time.value.vTime,
        timeType: time.value.vType,
    };

    for (let x of items.value) {
        let con1 = x.time == data.time;
        let con2 = x.timeType == data.timeType;
        let con3 = x.type == data.type;
        if (con1 && con2 && con3) flag = true;
    }

    if (!flag) {
        store.commit("notifications/push", {
            success: t("server.notification_added"),
        });
    }

    if (flag) {
        store.commit("notifications/push", {
            error: t("server.notification_not_added"),
        });
    }

    if (!flag) items.value.push(data);

    let newObject = JSON.parse(JSON.stringify(items.value));
    for (let x of newObject) {
        delete x["text"];
    }

    store.commit("schedule/setNotification", newObject);

};

const del = (val) => {
    items.value.reverse().splice(val, 1);
    items.value.reverse();

    let newObject = JSON.parse(JSON.stringify(items.value));
    for (let x of newObject) {
        delete x["text"];
    }

    store.commit("schedule/setNotification", newObject);
};

const toggle = () => {
    expand.value = !expand.value;

    if (dropDownRef.value.clientHeight == 0) {
        gsap.to(dropDownRef.value, {
            scaleY: 1,
            height: "auto",
            duration: 0.2,
            opacity: 1,
            ease: "power3.out",
            transformOrigin: "top",
        });
    } else {
        gsap.to(dropDownRef.value, {
            scaleY: 0,
            height: 0,
            opacity: 0,
            duration: 0.2,
            ease: "power3.out",
            transformOrigin: "top",
        });
    }
};

const getToLocal = () => {
    for (let x of notification.value) {
        let result = options.value.filter((val) => {
            let con1 = Number(val.vTime) == Number(x.time);
            let con2 = val.vType.toLowerCase() == x.timeType.toLowerCase();
            return con1 && con2;
        })[0];

        let email = t("schedule.email_notification");
        email = email + t("schedule.set") + " " + result.display;

        let data = {
            text: email,
            type: x.type,
            time: String(x.time),
            timeType: x.timeType,
        };

        items.value.push(data);
    }
};

const setToRemote = () => {
    let data = {
        text:
            t("schedule.email_notification") +
            t("schedule.set") +
            " " +
            t("schedule.15_min_before"),
        type: "email",
        time: "15",
        timeType: "minutes",
    };

    items.value.push(data);
    store.commit("schedule/setNotification", [data]);
};

const options = computed(() => {
    const now = new Date().getTime();
    const meetingStart = new Date(datetime.value).getTime();
    const timeUntilMeeting = (meetingStart - now) / (1000 * 60); // Time in minutes

    return [
        {
            display: t("schedule.15_min_before"),
            value: "15_min_before",
            vTime: 15,
            vType: "minutes",
        },
        {
            display: t("schedule.30_min_before"),
            value: "30_min_before",
            vTime: 30,
            vType: "minutes",
        },
        {
            display: t("schedule.1_hour_before"),
            value: "1_hour_before",
            vTime: 1,
            vType: "hour",
        },
        {
            display: t("schedule.3_hour_before"),
            value: "3_hour_before",
            vTime: 3,
            vType: "hour",
        },
        {
            display: t("schedule.1_day_before"),
            value: "1_day_before",
            vTime: 1,
            vType: "day",
        },
    ].filter(option => {
        const optionTimeInMinutes = option.vType === "minutes" ? option.vTime : 
                                    option.vType === "hour" ? option.vTime * 60 : 
                                    option.vType === "day" ? option.vTime * 1440 : 0;
        return optionTimeInMinutes <= timeUntilMeeting;
    });
});

const itemsType = computed(() => [
    { display: t("schedule.email_notification"), value: "email" },
]);

const types = computed(() => {
    const data = [
        { display: t("schedule.email_notification"), value: "email" },
    ];
    data[0].selected = true;
    return data;
});

const datetime = computed(() => store.state.schedule.datetime);
const notification = computed(() => store.state.schedule.notification);
const tip = computed(() => store.state.user.tip);
const lang = computed(() => store.state.user.lang);

onMounted(() => {
    if (!router.currentRoute.value.params.edit) {
        setToRemote();
    } else {
        getToLocal();
    }
});

watch(isMobile, (newVal, oldVal) => {
    expand.value = true
    if (newVal == 30) {
        gsap.to(dropDownRef.value, {
            scaleY: 1,
            height: "auto",
            duration: 0.2,
            opacity: 1,
            ease: "power3.out",
            transform: "none",
            onComplete: () => (dropDownRef.value.style.transform = "none"),
        });
    }
});

watch(lang, (newVal, oldVal) => {
    items.value = [];
    getToLocal();
});

const closeModal = () => {
    gsap.to(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
    });
    gsap.to(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
        onComplete: () => store.commit("modals/notificationsModal", false),
    });
};

onMounted(() => {
    gsap.from(".modal_page", {
        opacity: 0,
        duration: 0.3,
        ease: "power2.out",
    });

    gsap.from(".modal_window", {
        scale: 0.7,
        duration: 0.3,
        ease: "power2.out",
    });
});
</script>


<style scoped>
.modal_page {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(41, 41, 41, 0.6);
    z-index: 8;
}

.modal_window {
    max-width: 450px;
    width: 100%;
    padding: 30px;
    gap: 30px;
    padding-left: 60px;
    padding-right: 60px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
    background-color: var(--shade3);
    border-radius: 15px;
}

.wrapper {
    display: grid;
    gap: 10px;
}

.items {
    font-size: 14px;
    justify-content: space-between;
    max-width: 460px;
}

.item1 {
    background-color: #edfcf6a1;
    padding: 10px;
    border: 1px solid #1252dd1a;
    border-radius: 15px;
    color: var(--primary);
}

[data-theme="blue"] .item1 {
    background-color: #EDF1FC;
}

.close {
    transition: all ease 0.2s;
}

.close:focus,
.close:hover {
    transform: scale(1.2);
}

@media only screen and (min-width: 576px) {
    .show_on_mobile {
        display: none;
    }
}

@media only screen and (min-width: 879px) {
    .wrapper {
        display: flex;
    }
}

@media only screen and (min-width: 1311px) and (max-width: 1453px) {
    .wrapper {
        display: grid;
    }
}

@media only screen and (max-width: 576px) {
    .grid {
        display: flex;
        flex-direction: column;
    }

    .hide_on_mobile {
        display: none;
    }

    .sm-margin {
        margin-bottom: 10px;
    }
}



@media only screen and (max-width: 576px) {
    .modal_window {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
}
</style>