<template>
  <button 
    ref="buttonRef" 
    class="button center uppercase relative px-15 b-box" 
    :disabled="disabled || loading" 
    :style="styling" 
    :type="buttonType"
  >
    <pulse-loader :loading="load" :color="'var(--primary)'" size="10px" />
    <slot v-if="!load" />
  </button>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  width: String,
  height: String,
  color: String,
  opacity: String,
  font: String,
  block: Boolean,
  min: String,
  disabled: Boolean,
  icon: String,
  outlined: String,
  type: {
    type: String,
    default: "button"
  },
  loading: Boolean
});

const buttonRef = ref(null);

const load = computed(() => {
  return props.loading === true && props.loading !== "" && typeof props.loading !== "undefined";
});

const styling = computed(() => {
  let ob = {
    height: props.height ? props.height + "px" : undefined,
    minWidth: props.width ? props.width + "px" : undefined,
    backgroundColor: props.color,
    opacity: props.opacity,
    fontSize: props.font ? props.font + "px" : undefined,
  };

  if (props.block) {
    ob.width = "100%";
  } else {
    ob.width = "auto";
  }

  if (props.outlined === "") {
    ob.border = "1px solid black";
    ob.color = "black";
    ob.backgroundColor = "transparent";
  }

  if (props.min === "" || props.min === "true") {
    ob.minWidth = "0";
  }

  return ob;
});

const buttonType = computed(() => props.type ?? "button");

const onFocus = () => {
  buttonRef.value?.focus();
};

defineExpose({ onFocus });
</script>

<style scoped lang="less">
.button {
  min-width: 150px;
  height: 50px;
  font-family: Roboto;
  font-size: 16px;
  outline: none;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  border-radius: 15px;
  transition: all 0.2s ease;
  border: 1.5px solid var(--shade4);
}

.button:hover {
  filter: brightness(1.05);
}

.button:disabled {
  min-width: 95px;
  background-color: #444954;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  outline: none;
  pointer-events: none;
}

.button:disabled:hover {
  background-color: #425464;
}

.button:focus-visible {
  outline: none;
  border: 1.5px solid var(--primary);
}
</style>
