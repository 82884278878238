<template>
  <FocusLoop>
    <div class="modal_page center">
      <form class="modal_window d-flex d-col space-between" @submit.prevent="post">
        <div class="d-flex space-between align-center">
          <div class="font-18">
            {{ $t("modal.custom.title") }}
          </div>
          <button type="button" class="center" @click="closeModal">
            <s-icon height="16" color="white">
              close
            </s-icon>
          </button>
        </div>
        <div class="mt-30">
          <s-dropdown v-model="repeatingValue" :items="data" />
        </div>
        <div class="d-flex justify-end mt-40">
          <s-btn type="button"  class="mr-10 black--text" @click="closeModal">
            {{ $t("modal.cancel") }}
          </s-btn>
          <s-btn type="submit" class="green">
            {{ $t("modal.save") }}
          </s-btn>
        </div>
      </form>
    </div>
  </FocusLoop>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { gsap } from "gsap";
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useMq } from "vue3-mq";

const store = useStore();
const router = useRouter();
const { t } = useI18n();
const mq = useMq();

const repeatingValue = ref("");
const data = computed(() => [
  {
    display: t("modal.custom.no_repeat"),
    value: "none",
    selected: true,
  },
  {
    display: t("modal.custom.daily"),
    value: "daily",
    selected: false,
  },
  {
    display: t("modal.custom.weekly"),
    value: "weekly",
    selected: false,
  },
  {
    display: t("modal.custom.monthly"),
    value: "monthly",
    selected: false,
  },
  {
    display: t("modal.custom.annually"),
    value: "annually",
    selected: false,
  },
  {
    display: t("modal.custom.everyday"),
    value: "workdays",
    selected: false,
  },
]);

const repeating = computed(() => store.state.schedule.repeating);


const post = () => {
  store.commit("schedule/setRepeating", repeatingValue.value.value);
  closeModal();
};

const closeModal = () => {
  gsap.to(".modal_page", {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });
  gsap.to(".modal_window", {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
    onComplete: () => store.commit("modals/repeatingModal", false),
  });
};

onMounted(() => {
  if (!repeating.value) return;
  data.value.forEach((val) => (val.selected = false));
  let active = data.value.find((val) => val.value == repeating.value);
  if (repeating.value) active.selected = true;

  gsap.from(".modal_page", {
    opacity: 0,
    duration: 0.3,
    ease: "power2.out",
  });

  gsap.from(".modal_window", {
    scale: 0.7,
    duration: 0.3,
    ease: "power2.out",
  });
});
</script>

<style scoped>
.modal_page {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(41, 41, 41, 0.6);
  z-index: 8;
}

.modal_window {
  max-width: 450px;
  width: 100%;
  padding: 30px;
  padding-left: 60px;
  padding-right: 60px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.35);
  background-color: var(--shade3);
  border-radius: 15px;
}

@media only screen and (max-width: 576px) {
  .modal_window {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
}
</style>