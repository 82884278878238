<template>
  <div class="relative ">
    <div class="center h100 ">
      <button ref="button" class="holder center mr-10" @click="toggle">
        <img class="flag" :src="'/img/static/' + lang + '-flag.svg'" alt="">
        <span class="uppercase ml-10">{{ lang }}</span>
      </button>
    </div>
    <transition :name="mq.smMinus ? 'dropdown-mobile' : 'dropdown' ">
      <div v-if="show" class="dropdown" :style="styling">
        <RadioFlags v-model="selection" @childEvent="setLang" v-hotkey="{ esc: close }" v-click-outside:holder="toggle"
          :options="options" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import RadioFlags from '@/components/Atoms/RadioFlags.vue';
import { useI18n } from 'vue-i18n';
import { useMq } from "vue3-mq";

const { locale } = useI18n();
const props = defineProps(["center"]);
const mq = useMq();

const store = useStore();
const lang = computed(() => store.state.user.lang);
const email = computed(() => store.state.user.email);

const active = ref(0);
const selection = ref(null);
const show = ref(false);

const styling = computed(() => (props.center == "" ? { right: "-59px" } : { right: "0px" }));
const options = computed(() => (process.env.VUE_APP_BUILD == "prod" ? ["en", "de", "sk"] : [
  "de",
  "en",
  "es",
  "el",
  "fr",
  "nl",
  "pl",
  "pt",
  "sk",
  "zh-cn",
  "zh-tw",
  "hi",
]));

const setLang = (val) => {
  store.commit("user/setLang", val);
  if (email.value) store.dispatch("user/setLang", val);
  if (!email.value) store.dispatch("user/setLangGuest", val);
  active.value = options.value.indexOf(val);
  locale.value = val
  selection.value = val;
};

const close = () => {
  show.value = false;
};

const toggle = () => {
  show.value = !show.value;
};

watch(lang, (newValue) => {
  setLang(newValue)
});

onMounted(async () => {
  active.value = options.value.indexOf(lang.value);
  locale.value = lang.value
  selection.value = lang.value;
});
</script>

<style scoped>
.holder {
  border-radius: 10px;
  border: solid 1px var(--shade4);
  height: 40px;
  width: 80px;
  cursor: pointer;
  min-width: max-content;
  padding: 0px 10px;
  box-sizing: border-box;
  transition: 0.3s all ease;
}

.uppercase {
  color: black;
}

.flag {
  max-height: 24px;
  max-width: 24px;
}

.holder:hover {
  background-color: var(--shade3);
}

.dropdown {
  background-color: var(--shade1);
  width: 220px;
  position: absolute;
  right: 0;
  border-radius: 10px;
}

.langSelector:focus {
  outline: none;
  background-color: var(--shade2);
  ;
}

@media only screen and (max-width: 576px) {
  .dropdown {
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 120px;
  }

  .holder {
    border-radius: 3px;
    border: solid 1px #42515e;
    height: 40px;
    width: 80px;
    cursor: pointer;
    margin-right: 10px;
  }
}
</style>